<template>
  <div>
    <!-- #region Form -->
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3">{{$t("questionCreatePage.pageTitle")}}</strong>  
          </CCardHeader>
          <CCardBody>
            <span class="caption">
              {{ $t("common.createCaption") }}
            </span>
            <CForm class="submit_form">
              <!-- #region 質問 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{$t("questionCreatePage.name")}}</strong></CCol>
                <CCol>
                  <CInput v-model="requestJa.name" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.requestJa.name.$dirty &&
                      !$v.requestJa.name.required
                    "
                  >
                    {{ $t("validations.required", { vName: $t("common.name")})}}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.requestJa.name.$dirty &&
                      !$v.requestJa.name.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("common.name"),vMax: $v.requestJa.name.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 質問 -->

              <!-- #region 説明文 -->
              <CRow>
                <CCol class="label"><strong>{{$t("questionCreatePage.description")}}</strong></CCol>
                <CCol>
                  <CTextarea
                    class="mb-2"
                    v-model="requestJa.description"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.requestJa.description.$dirty &&
                      !$v.requestJa.description.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("common.description"),vMax: $v.requestJa.description.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明文 -->

              <!-- #region 回答方法 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{$t("questionCreatePage.answerMethod")}}</strong></CCol>
                <CCol>
                  <CInputRadioGroup
                    :options="answerMethods"
                    :checked.sync="requestJa.answerMethod"
                  />
                  <!-- TODO:選択してもバリデーションの文が消えない -->
                  <!-- <p
                    class="text-danger input-info"
                    v-if="
                      $v.requestJa.answerMethods.$dirty &&
                      !$v.requestJa.answerMethods.required
                    "
                  >
                    回答方法を選択してください。
                  </p> -->
                </CCol>
              </CRow>
              <!-- #endregion 回答方法 -->

              <!-- #region 選択肢 -->
              <CRow v-if="requestJa.answerMethod === `select`">
                <CCol class="label"><strong class="required-mark">{{$t("questionCreatePage.choices")}}</strong></CCol>
                <CCol
                >
                <CButton
                  color="info"
                  size="sm"
                  class="m-2"
                  @click="onAddInputClicked()"
                ><i class="icon cil-plus"></i>{{$t("questionCreatePage.addChoicesButton")}}</CButton>
                <div
                  v-for="(item,i) in inputObjects"
                  :key=i
                >
                  <CInput
                    v-if="i!== 0"
                    class="w-75 float-left"
                    v-model="requestJa.choices[i]"
                  />
                  <!-- TODO:バリデーションが表示されていないのでなおすこと -->
                  <!-- <p
                    class="text-danger input-info"
                    v-if="$v.requestJa.choices.$dirty && !$v.requestJa.choices.maxLength"
                  >
                    {{ $t("validations.maxLength", { vName: $t("common.name"),vMax: $v.requestJa.choices[i].$params.maxLength.max })}}
                  </p> -->
                  <CButton
                    v-if="i!== 0"
                    color="danger"
                    size="sm"
                    class="m-2"
                    @click="onDeleteInputClicked(requestJa.choices[i])"
                  ><i class="icon cil-trash"></i> {{$t('common.delete')}}</CButton>
               
                </div>
                </CCol>
              </CRow>
              <!-- #endregion 選択肢 -->


              <!-- #region 入力例text -->
              <CRow v-if="requestJa.answerMethod === `text`">
                <CCol class="label"><strong>{{$t("questionCreatePage.inputExample")}}</strong></CCol>
                <CCol>
                  <CInput
                    v-if="requestJa.answerMethod === `text`"
                    class="mb-2"
                    v-model="requestJa.inputExample"
                  />
                </CCol>
              </CRow>
              <!-- #endregion 入力例text -->

              <!-- #region 入力例textarea -->
              <CRow v-if="requestJa.answerMethod === `textarea`">
                <CCol class="label"><strong>{{$t("questionCreatePage.inputExample")}}</strong></CCol>
                <CCol>
                  <CTextarea
                    class="mb-2"
                    v-model="requestJa.inputExample"
                  />
                </CCol>
              </CRow>
              <!-- #endregion 入力例textarea -->

              <!-- #endregion 入力フィールド -->
            </CForm>
          </CCardBody>

          <!-- #region プレビュー -->
          <CCardBody>
            <div 
              class="mt-2"
              v-if="requestJa.answerMethod">
              <CRow>
                <CCol>
                  <strong class="card-title mr-3"> {{$t("questionCreatePage.preview")}}</strong>
                  <p>{{$t("questionCreatePage.previewGuide")}}</p>
                </CCol>
              </CRow>
              <CRow>
                <CCol class="border" >
                    <h4 v-html="requestJa.name"></h4>
                    <p v-html="requestJa.description"></p>
                    <CSelect
                    v-if="requestJa.answerMethod === `select`"
                      class="w-25 mb-2"
                      :options="requestJa.choices"
                    />
                    <CInput
                      v-if="requestJa.answerMethod === `text`"
                      class="mb-2"
                      v-model="requestJa.inputExample"
                    />
                    <CTextarea
                      v-if="requestJa.answerMethod === `textarea`"
                      class="mb-2"
                      v-model="requestJa.inputExample"
                    />
                </CCol>
              </CRow>
            </div>
            
           <!-- #region 確認ボタン -->
            <CButton
              class="px-5 mt-4"
              color="info"
              @click="onCheckClicked()"
              v-bind:class="activeStatus"
            >
              <i class="cil-task"></i> {{ $t("common.check") }}
            </CButton>
            <!-- #endregion 確認ボタン -->
          </CCardBody>
          <!-- #endregion プレビュー -->

          <CCardFooter>
            <CButton color="secondary" class="" @click="onBackClicked()"
              ><i class="icon cil-arrow-left mr-2"></i
              >{{ $t("common.back") }}</CButton
            >
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Form -->

    <!-- #region Check -->
    <CModal
      color="info"
      size="lg"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="checkModal"
    >
      <CForm class="submit_form">
        <!-- #region 名称 -->
        <CRow>
          <CCol class="label"
            ><strong>{{ $t("common.name") }}</strong></CCol
          >
          <CCol
            ><span> {{ requestJa.name }} </span></CCol
          >
        </CRow>
        <!-- #endregion 名称 -->

        <!-- #region 説明 -->
        <CRow>
          <CCol class="label"
            ><strong>{{ $t("common.description") }}</strong></CCol
          >
          <CCol
            ><span> {{ requestJa.description }} </span></CCol
          >
        </CRow>
        <!-- #endregion 説明 -->

        <!-- #region 回答方法 -->
        <CRow>
          <CCol class="label"
            ><strong>{{$t("questionCreatePage.answerMethod")}}</strong></CCol
          >
          <CCol
            ><span>{{checkModalAnswer.label}}</span></CCol
          >
        </CRow>
        <!-- #endregion 回答方法 -->

        <!-- #region 選択肢 -->
        <CRow v-if="requestJa.answerMethod === `select`">
          <CCol class="label"
            ><strong>{{$t("questionCreatePage.choices")}}</strong></CCol
          >
          <CCol
            ><ul
              class="choices"
              v-for="(item,i) in requestJa.choices"
              :key="i"
            >
              <li v-if="i!==0">・{{item}}</li>
            </ul></CCol
          >
        </CRow>
        <!-- #endregion 選択肢 -->

        <!-- #region 入力例 -->
        <CRow v-if="requestJa.answerMethod === `text`|| requestJa.answerMethod === `textarea`">
          <CCol class="label"
            ><strong>{{$t("questionCreatePage.inputExample")}}</strong></CCol
          >
          <CCol
            ><span> {{ requestJa.inputExample }} </span></CCol
          >
        </CRow>
        <!-- #endregion 入力例 -->

        
 
      </CForm>
      <template #header> {{ $t("common.check") }} </template>
      <template #footer>
        <CButton @click="checkModal = false" color="secondary">
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>
        <CButton color="success" @click="onRegisterClicked()">
          <i class="icon cil-save mr-1"></i> {{ $t("common.register") }}
        </CButton>
      </template>
    </CModal>
    <!-- #endregion Check -->

    <SuccessModal :successModal="successModal" @close="successModal = $event" />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
import i18n from "@/i18n" 
// import axios from "axios";
// import Question from "./List";

import {
  required,
  // numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "QuestionEdit",


  computed: {
    activeStatus() {
      return this.$v.requestJa.$invalid ? "inactive" : "active";
    },

    showSuccessModal() {
      return this.successModal || this.deleteSuccessModal;
    },
    checkModalAnswer(){
      if(this.requestJa.answerMethod){
        return this.answerMethods.find((v) => v.value === this.requestJa.answerMethod);
      }else{
        return ""
      }
    },
  },

  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,
      deleteSuccessModal: false,
      //#endregion Flag
      increment:1,

      // getType:[],

      //#region Message
      errorMessage: "error",
      //#endregion Message

      inputObjects:[0],

      answerMethods: [
        { value: 'select', label: `${i18n.t("questionCreatePage.method.select")}`},
        { value: 'text', label: `${i18n.t("questionCreatePage.method.text")}`},
        { value: 'textarea', label: `${i18n.t("questionCreatePage.method.textarea")}`},
      ],

      //#region request
      // 日本語入力データ
      requestJa: {
        language: "ja",
        name: "",
        description: "",
        answerMethod:"",
        choices:["選択してください"],
        inputExample:""
      },



      // editZh:{
      //   language: "zh",
      //   name: "",
      //   description: ""
      // },
      // editEn:{
      //   language: "en",
      //   name: "",
      //   description: ""
      // },

      requests:[],
      //#endregion request

      //#region Response data
      response: [],
      reponseObj:{},
      //#endregion Response data

      isDisplayEnabled: [
        {
          value: false,
          label: `${i18n.t("mediaKindEditPage.isDisplayEnabled.disable")}`,
        },
        {
          value: true,
          label: `${i18n.t("mediaKindEditPage.isDisplayEnabled.enable")}`,
        },
        
      ],
    };
  },

  validations() {
    return {
      requestJa: {
        name: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(1000),
        },
        // //TODO:バリデーションが上手く動いてない
        // answerMethods:{
        //   required
        // },
        // //TODO:バリデーションが上手く動いてない
        // choices:{
        //   maxLength: maxLength(10),
        // },
      },
    };
  },

  methods: {

    //#region Event

     /** 確認ボタン押下 */
    onCheckClicked() {
      this.$v.requestJa.$invalid
        ? this.$v.requestJa.$touch()
        : (this.checkModal = true);
    },

    /**登録ボタン押下 */
    /** 登録ボタン押下 */
    onRegisterClicked() {
      this.reqPost();
    },

    /**入力欄追加クリック */
    onAddInputClicked(){
      this.addInput();
    },

    /**入力欄削除クリック */
    onDeleteInputClicked(string){
      this.deleteInput(string);
    },



    //#endregion Event

    //言語変換
    // languageConvert(datas){
    //   datas.forEach(element => {
    //     if(element.language === this.$store.state.currentLanguage){
    //       this.requestJa.language = element.language;
    //       this.requestJa.name = element.name;
    //       this.requestJa.description = element.description;
    //     }
    //   });
    // },

    /**取得データのマスタータイプ取得 */
    // getMasterType(type){
    //   console.log(this.selectItems)
    //   const typeObj = this.selectItems.filter((v) => v.value === type);
    //   console.log(typeObj)

    //   this.masterTypeLabel = typeObj.label;
    // },

    /**取得データセット */ 
    dataInsert(datas){
      datas.forEach(element => {
         // NOTE:多言語用の画面でないため、日本語データだけ挿入
         // 日本語データ
        if(element.language === `ja`){
          this.requestJa.language = element.language;
          this.requestJa.name = element.name;
          this.requestJa.description = element.description;
          this.requestJa.displayable = element.displayable;
        }

        // //中国語データ
        // if(element.language === `zh`){
        //   this.editZh.language = element.language;
        //   this.editZh.name = element.name;
        //   this.editZh.description = element.description;
        // }

        // //英語データ
        // if(element.language === `en`){
        //   this.editEn.language = element.language;
        //   this.editEn.name = element.name;
        //   this.editEn.description = element.description;
        // }
      });
    },

    //#region Request

    /**送信 */
    reqPost() {
      this.checkModal = false;
      this.successModal = true;
      // const url = `${Question.url}`;
      // // リクエスト初期化
      // this.requests = [];
      // // リクエストデータのリストにオブジェクト追加
      // this.requests.push(this.requestJa);
      // const body = this.requests;

      // axios
      //   .post(url, body, {
      //     headers: {
      //       Authorization: `Bearer ${this.$keycloak.token}`,
      //       "Accept-Language": this.$i18n.locale,
      //     },
      //   })
      //   .then((resp) => {
      //     // console.log("resp.data" + JSON.stringify(resp.data));
      //     this.checkModal = false;
      //     this.successModal = true;
      //     console.log(resp.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.errorMessage = `${err.name} : ${err.message}`;
      //     this.checkModal = false;
      //     this.errorModal = true;
      //   });
    },

    //#endregion Request



    //#region Method

    /**入力欄追加 */
    addInput(){

      const id = this.increment;

      this.increment++;

      // this.inputObjects.push({id:id})
      this.inputObjects.push(id)
      
      console.log(this.increment)
    },

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    backPage() {
      this.$router.push({
        path: "/operator/question/list"
      });
    },

    /**入力欄削除 */
    deleteInput(string){
      const  index = this.requestJa.choices.indexOf(string)
      if (index > 0) {
        console.log("delete")
        // プレビューのselectboxの中身削除
        this.requestJa.choices.splice(index, 1);
        // 選択肢入力フォームを削除
        this.inputObjects.splice(index,1);
      }
    }

    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>